<template>
  <div v-if="bannersGlobalCount != 0" class="global-main-banner">
    <section class="mt-5 mb-5 pt-5 pb-5 blue-grey darken-4 z-depth-4">
      <b-container class="">
        <b-row>
          <b-col cols="12" class="content-banner">
            <b-carousel
              id="carousel-1"
              v-model="slide"
              :interval="7500"
              controls
              indicators
              img-width="1440"
              img-height="580"
              @sliding-start="onSlideStart"
              @sliding-end="onSlideEnd"
            >
              <b-carousel-slide
                v-for="banner in bannersGlobal"
                :key="banner.id"
              >
                <template #img>
                  <a :href="banner.link">
                    <img
                      class="d-block img-fluid w-100"
                      width="1440"
                      height="480"
                      :src="`${path.storage_files + banner.images.url}`"
                      alt="image slot"
                    />
                  </a>
                </template>
              </b-carousel-slide>
            </b-carousel>
          </b-col>
        </b-row>
      </b-container>
    </section>
  </div>
</template>

<script>
export default {
  name: "MainBanner",
  components: {},
  data() {
    return {
      bannersGlobal: [],
      bannersGlobalCount: 0,
      slide: 0,
      sliding: null,
      path: {
        base_url: process.env.VUE_APP_BASE_URL,
        endpoint: process.env.VUE_APP_ENDPOINT,
        storage_files: process.env.VUE_APP_STORAGE_FILES,
      },
    };
  },

  mounted() {},

  /*
   * Ajuste segun categoria
   * Hereda de Categoria Padre
   */
  async created() {
    await fetch(this.path.endpoint + "/banners/?isGlobal=true")
      .then((response) => response.json())
      .then((data) => {
        this.bannersGlobal = data;
        console.log(data);
      })
      .catch((err) => {
        console.error(err);
      });

    await fetch(this.path.endpoint + "/banners/count/?isGlobal=true")
      .then((response) => response.json())
      .then((data) => {
        this.bannersGlobalCount = data;
      });

    //axios.get('http://localhost:1337/blogs/count').then(response => {this.postCount = response.data})
  },
  /* End Ajuste */

  methods: {
    onSlideStart(slide) {
      this.sliding = true;
    },
    onSlideEnd(slide) {
      this.sliding = false;
    },
  },
};
</script>
