import http from "@/services/http-common";

class DataStats {

    getStats() {
        return http.get('/stats/');
    }

}

export default new DataStats();
