<template>
  <div class="category-estadisticas categories" :class="`${stats.canonical}`">
    <HeaderCategory
      :nameCategory="`${cfg.nameCategory}`"
      imageCategory="/images/categorias/estadisticas.png"
    ></HeaderCategory>
    <!-- Ajustes Manuales Temporales -->

    <b-container class="mt-5 mb-5 pt-2">
      <b-row class="">
        <b-col cols="12" class="">
          <h1><i class="fas fa-chart-pie"></i> {{ stats.title }}</h1>
          <hr class="line sm" />
          <section class="markdown-content mb-5">
            <vue-simple-markdown
              :source="`${stats.description}`"
            ></vue-simple-markdown>
          </section>
        </b-col>
      </b-row>
      <b-row class="mb-4">
        <b-col class="mb-4 text-center" col lg="4" md="4" sm="12">
          <h3 class="latest-post">
            {{ stats.world.title }}
          </h3>

          <b-img
            v-bind:src="`${path.storage_files + stats.world.image_cover.url}`"
            fluid-grow
            :alt="`${stats.title}`"
            class="shadow-2 mb-3"
          ></b-img>

          <b-button
            color="red darken-2"
            class="rojo"
            href="https://nextcloud.sre.gob.mx/index.php/s/gLPJrZF4BsxxiJD"
            target="_blank"
          >
            Infografía General Mundo 2021
          </b-button>
        </b-col>
        <b-col class="mt-3" col lg="8" md="8" sm="12">
          <b-card no-body class="mt-4">
            <b-tabs card>
              <b-tab active>
                <template #title>
                  <i class="fas fa-chart-pie"></i>
                  {{ stats.world.titleByContinent }}
                </template>
                <span
                  v-for="continent in stats.world.byContinent"
                  :key="continent.id"
                >
                  <b-button
                    size="md"
                    :variant="`${!continent.link ? 'secondary' : 'info'}`"
                    class="m-1 white-text"
                    target="_blank"
                    :href="`${continent.link}`"
                    :class="`${!continent.link ? 'disabled' : ''}`"
                  >
                    <i class="fas fa-globe"></i> {{ continent.name }}
                  </b-button>
                </span>
              </b-tab>

              <b-tab>
                <template #title>
                  <i class="fas fa-chart-pie"></i>
                  {{ stats.world.titleByMexicanState }}
                </template>
                <span
                  v-for="mxState in stats.world.byMexicanState"
                  :key="mxState.id"
                >
                  <b-button
                    size="md"
                    :variant="`${!mxState.link ? 'secondary' : 'success'}`"
                    class="m-1 "
                    target="_blank"
                    :href="`${mxState.link}`"
                    :class="`${!mxState.link ? 'disabled' : ''}`"
                  >
                    <i class="fas fa-check-double"></i> {{ mxState.year }}
                  </b-button>
                </span>
              </b-tab>
            </b-tabs>
          </b-card>
        </b-col>
      </b-row>

      <b-row>
        <b-col class="mb-4 text-center" col lg="4" md="4" sm="12">
          <h3 class="latest-post">
            {{ stats.usa.title }}
          </h3>

          <b-img
            v-bind:src="`${path.storage_files + stats.usa.image_cover.url}`"
            fluid-grow
            :alt="`${stats.title}`"
            class="shadow-2 mb-3"
          ></b-img>

          <b-button
            color="red darken-2"
            class="rojo"
            href="https://nextcloud.sre.gob.mx/index.php/s/yWBDWjfBkxaqpj5"
            target="_blank"
          >
            Ficha General EE.UU.
          </b-button>
        </b-col>
        <b-col class="mt-3" col lg="8" md="8" sm="12">
          <b-card no-body class="mt-4">
            <b-tabs card justified>
              <b-tab active>
                <template #title>
                  <i class="fas fa-chart-pie"></i>
                  {{ stats.usa.titleByConsularDistrict }}
                </template>
                <span
                  v-for="consular in stats.usa.byConsularDistrict"
                  :key="consular.id"
                >
                  <b-button
                    size="md"
                    :variant="`${!consular.link ? 'secondary' : 'danger'}`"
                    class="m-1"
                    target="_blank"
                    :href="`${consular.link}`"
                    :class="`${!consular.link ? 'disabled' : ''}`"
                  >
                    <i class="fas fa-check-double"></i> {{ consular.year }}
                  </b-button>
                </span>
              </b-tab>

              <b-tab>
                <template #title>
                  <i class="fas fa-chart-pie"></i>
                  {{ stats.usa.titleByMexicanState }}
                </template>
                <span
                  v-for="mxState in stats.usa.byMexicanState"
                  :key="mxState.id"
                >
                  <b-button
                    size="md"
                    :variant="`${!mxState.link ? 'secondary' : 'success'}`"
                    class="m-1"
                    target="_blank"
                    :href="`${mxState.link}`"
                    :class="`${!mxState.link ? 'disabled' : ''}`"
                  >
                    <i class="fas fa-check-double"></i> {{ mxState.year }}
                  </b-button>
                </span>
              </b-tab>

              <b-tab>
                <template #title>
                  <i class="fas fa-chart-pie"></i>
                  {{ stats.usa.titleByUsaState }}
                </template>
                <span
                  v-for="usaState in stats.usa.byUsaState"
                  :key="usaState.id"
                >
                  <b-button
                    size="md"
                    :variant="`${!usaState.link ? 'secondary' : 'dark'}`"
                    class="m-1"
                    target="_blank"
                    :href="`${usaState.link}`"
                    :class="`${!usaState.link ? 'disabled' : ''}`"
                  >
                    <i class="fas fa-check-double"></i> {{ usaState.year }}
                  </b-button>
                </span>
                <span> </span>
              </b-tab>

              <b-tab>
                <template #title>
                  <i class="fas fa-chart-pie"></i>
                  {{ stats.usa.titleHistoricalUsa }}
                </template>
                <span
                  v-for="usaState in stats.usa.historicoUSA"
                  :key="usaState.id"
                >
                  <b-button
                    size="md"
                    :variant="`${!usaState.link ? 'secondary' : 'warning'}`"
                    class="m-1"
                    target="_blank"
                    :href="`${usaState.link}`"
                    :class="`${!usaState.link ? 'disabled' : ''}`"
                  >
                    <i class="fas fa-check-double"></i> {{ usaState.year }}
                  </b-button>
                </span>
              </b-tab>
            </b-tabs>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import DataStats from "@/services/data-stats";

import HeaderCategory from "@/components/Custom/Layout/HeaderCategory.vue";
import MainBanner from "@/components/Global/MainBanner.vue";

export default {
  name: "CategoryStats",
  components: {
    DataStats,
    HeaderCategory,
    MainBanner,
  },

  data() {
    return {
      stats: {
        world: {
          image_cover: "",
        },
        usa: {
          image_cover: "",
        },
      },
      path: {
        base_url: process.env.VUE_APP_BASE_URL,
        endpoint: process.env.VUE_APP_ENDPOINT,
        storage_files: process.env.VUE_APP_STORAGE_FILES,
      },
      cfg: {
        showMenu: this.$route.meta.cfgShowMenu,
        category: this.$route.meta.cfgCategory,
        nameCategory: this.$route.meta.cfgNameCategory,
      },
    };
  },

  mounted() {
    document.title = this.cfg.nameCategory + " | IME";
    this.statItems();
  },

  methods: {
    statItems() {
      DataStats.getStats()
        .then((response) => {
          this.stats = response.data;
          console.log(response.data);
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
};
</script>
