<template>
  <section class="category-header">
    <div
      class="header-category z-depth-5"
      :style="`background-image: url('${imageCategory}')`"
    >
      <div class="gradiente">
        <b-container class="">
          <h1 class="display-3 white-text text-center text-shadow super-strong">
            {{ nameCategory }}
          </h1>
          <!-- Ajustar de acuerdo a la categoria -->
        </b-container>
      </div>
    </div>
    <section
      v-if="category === 'CategoryVinculacion'"
      class="component-home-slide"
    >
      <div class="orange darken-4">
        <b-container>
          <b-row class="orange darken-4 header-top-menu  white-text light">
            <b-col>
              <ScrollComponent></ScrollComponent>
            </b-col>
          </b-row>
        </b-container>
      </div>
    </section>
  </section>
</template>
<script>
import ScrollComponent from "@/components/Custom/News/Scroll.vue";

export default {
  name: "HeaderCategory",
  components: {
    ScrollComponent,
  },

  props: {
    nameCategory: String,
    imageCategory: String,
  },

  computed: {
    category() {
      return this.$route.name;
    },
  },

  mounted() {
    //this.$route;
  },
};
</script>
